<template>
  <div class="ingredient-wrap">

    <div>
      <FormRow v-for="(item,index) in ingredientValue" :key="item.id"
               style="margin-top:10px">
        <template>
          <b-form-group
              :label="index === 0 ? 'Ingredients:':'' "
              label-cols-lg="3"
              label-align-lg="right"
              label-for="flavor-ingredients"
              :label-class="index === 0 ? 'requiredRow':''"
          >
            <b-form-select :options="filterIngredientOptions"
                           value-field="id"
                           :class="item.showSelectError? 'select-error':''"
                           :disabled="item.id === -1000"
                           :value="item.id"
                           @input="(selectedItem) =>onIngredientSelectChange('ingredients',index, selectedItem)">

            </b-form-select>

          </b-form-group>
        </template>
        <template #right>
          <div class="vo-row align-items-center input-percent-wrap">
            <b-input @blur="onIngredientBlur('ingredients',index)"
                     v-model="item.proportion"
                     :disabled="item.id === -1000"
                     :class="[item.id === -1000 ? 'input-disabled':'',item.showInputError? 'select-error':'']"
                     class="percent-input"
                     @input="e => onIngredientValueChange(index,e)"/>
            <span>%</span>
            <a href="javascript:void(0)" v-if="item.id !== -1000 && index > 0"
               @click="onIngredientDelete('ingredients',index)">Delete</a>
          </div>
        </template>
      </FormRow>
    </div>


    <FormRow >
      <b-col offset="3">
        <a href="javascript:void(0)" class="mt-4" @click="onIngredientAdd('ingredients')">+Add
          ingredirents</a>
      </b-col>
      <template #right>
      </template>
    </FormRow>


    <div v-if="terpenesValue.length > 0">
      <FormRow  :right-col="3"
               style="margin-top:10px"
               v-for="(item,index) in terpenesValue" :key="`terpenesValue` + item.id">
        <template>

          <b-form-group
              :label="index === 0 ? 'Terpenes:':'' "
              label-cols-lg="3"
              label-align-lg="right"
              label-for="flavor-terpenes"
              :label-class="index === 0 ? 'requiredRow':''"
          >
            <b-select :value="item.id"
                      value-field="id"
                      :class="item.showSelectError? 'select-error':''"
                      :options="item.id === -1000 ? otherOptions:filterTerpenesOptions"
                      :disabled="item.id === -1000"
                      @input="(selectedItem) =>onIngredientSelectChange('terpenes',index, selectedItem)">

            </b-select>

          </b-form-group>

        </template>

        <template #right>
          <div class="vo-row align-items-center input-percent-wrap">
            <b-input v-model="item.proportion"
                     @blur="onIngredientBlur('terpenes',index)"
                     @update="e => onTerpenesValueChange(index,e)"
                     :disabled="item.id === -1000"
                     :class="[item.id === -1000 ? 'input-disabled':'',item.showInputError? 'select-error':'']"

                     class="percent-input"/>
            <span>%</span>

            <a v-if="item.id !== -1000 " href="javascript:void(0)"
               @click="onIngredientDelete('terpenes',index)">Delete</a>
          </div>
        </template>
      </FormRow>

      <FormRow>
        <b-col offset="3">
          <a href="javascript:void(0)" class="mt-4" @click="onIngredientAdd('terpenes')">+Add
            terpenes</a>
        </b-col>
      </FormRow>

    </div>
    <div v-else>

      <FormRow>
        <b-form-group
            label="Terpenes:"
            label-cols-lg="3"
            label-align-lg="right"
        >
          <div style="height:100%;padding: 0 10px;" class="vo-row align-items-center">
            <a href="javascript:void(0)" @click="onIngredientAdd('terpenes')">+Add
              terpenes</a>
          </div>

        </b-form-group>
      </FormRow>
    </div>


  </div>
</template>


<script>
import FormRow from "@/components/FormRow.vue";

export default {
  name: "IngredirentEditor",
  components: {FormRow},
  props: {},
  data() {
    return {
      otherTerpenes: {
        text: "Other",
        proportion: "",
        id: -1000,
      },

      ingredientOptions: [
        {text: "Please select an option", id: null, disabled: true},
        {text: "THC", id: 10},
        {text: "THC1", id: 20},
        {text: "THC2", id: 30},
        {text: "THC3", id: 40},
      ],
      terpenesOptions: [
        {text: "Please select an option", id: null, disabled: true},
        {text: "THC", id: 10},
        {text: "THC1", id: 20},
        {text: "THC2", id: 30},
        {text: "THC3", id: 40},
      ],
      otherOptions: [
        {text: "Other", id: -1000, disabled: true},
      ],
      ingredientValue: [
        {proportion: "", id: null, text: ''},
      ],
      terpenesValue: [
        {text: "Other", id: -1000, proportion: "100", disabled: true},
      ],
      idleIngredient: 100
    }
  },

  computed: {
    filterIngredientOptions() {
      const selectedId = this.ingredientValue
          .filter(item => item.id > 0)
          .map(item => item.id);
      return this.ingredientOptions.map(
          item => {
            if (item.id != null) {
              item.disabled = selectedId.includes(item.id);
            }
            return item
          }
      );
    },
    filterTerpenesOptions() {
      const selectedId = this.terpenesValue
          .filter(item => item.id > 0)
          .map(item => item.id);
      return this.terpenesOptions.map(
          item => {
            if (item.id != null) {
              item.disabled = selectedId.includes(item.id);
            }
            return item
          }
      );
    },
  },
  methods: {

    getTypeData(type) {
      let list = null;
      switch (type) {
        case "ingredients":
          list = this.ingredientValue;
          break;
        case "terpenes":
          list = this.terpenesValue;
          break;
      }
      return list;
    },

    validateIngredients(type, checkInput = true, checkSelect = true) {
      let list = this.getTypeData(type);
      if (list == null) {
        return true;
      }
      let findValueHasError = false;
      list.forEach((item, index) => {
        const proportion = item.proportion
        if (checkInput) {
          if ((proportion === "" || proportion === undefined || proportion <= 0) && item.id !== -1000) {
            findValueHasError = true;
            this.$set(
                list,
                index,
                Object.assign(item, {showInputError: true})
            );
          } else {
            this.$set(
                list,
                index,
                Object.assign(item, {showInputError: false})
            );
          }
        }

        if (checkSelect) {
          //离开焦点了，判断是否满足百分之一百
          const selectedIngredientId = list[index].id;
          //-1000的时候给他通过
          const isSelectedIngredient =
              selectedIngredientId > 0 || selectedIngredientId === -1000;
          // log(`选择了成分？ ${isSelectedIngredient} ${index}`);
          if (!isSelectedIngredient) {
            //提示他要选择
            this.$set(
                list,
                index,
                Object.assign(list[index], {showSelectError: true})
            );
            findValueHasError = true;
          } else {
            this.$set(
                list,
                index,
                Object.assign(list[index], {showSelectError: false})
            );
          }
        }

      });
      return findValueHasError;
    },

    onIngredientBlur(type, index) {
      console.log('onBlur')

      let list = this.getTypeData(type);
      if (list == null) {
        return true;
      }
      if (list.length === 0 && type === 'terpenes') {
        this.handleIdle()
      }
      const item = list[index]
      //离开焦点了，判断是否满足百分之一百
      if (item.proportion === "" || item.id <= 0) {
        return;
      }

      if (this.validateIngredients(type)) {
        return;
      }

      this.handleIdle()

      this.$emit('change', this.ingredientValue, this.terpenesValue)
    },
    handleIdle() {
      const allList = [].concat(this.ingredientValue).concat(this.terpenesValue);
      console.log(allList)
      const result = allList
          .filter(item => item.id > 0)
          .map(item => item.proportion)
          .reduce((prev, current) => parseFloat(prev) + parseFloat(current), 0);
      console.log(`on blur 当前填入的百分比总合 ${result}`);
      const idle = 100 - result;
      // this.idleIngredient = idle;

      //如果有剩余的，加入other
      const otherIndex = this.terpenesValue.findIndex(item => item.id === -1000);
      if (idle <= 0) {
        if (otherIndex !== -1) {
          //没有剩余了，直接移除
          this.terpenesValue.splice(otherIndex, 1);
        }
        return;
      }

      const otherItem = Object.assign({}, this.otherTerpenes);
      otherItem.proportion = idle
      if (otherIndex !== -1) {
        this.$set(
            this.terpenesValue,
            otherIndex,
            otherItem
        )
      } else {
        otherItem.proportion = idle;
        this.terpenesValue.push(otherItem);
      }
    },
    onIngredientAdd(type) {
      if (this.validateIngredients('ingredients')) {
        return;
      }

      if (this.validateIngredients('terpenes')) {
        return;
      }

      let list = this.getTypeData(type);
      if (list == null) {
        return;
      }


      const filterOption = this.getFilterOptionData(type);
      //是否选中完所有的选项了
      if (filterOption.length === 0) {
        return;
      }

      //是否还有剩余的百分比，没有剩余的了，直接移除other
      const idle = this.calcAllIdleIngredient(type, -1);

      if (idle <= 0) {
        return;
      }

      if (type === 'ingredients') {
        list.push({id: null, proportion: ''});
      } else {
        const otherIndex = this.terpenesValue.findIndex(item => item.id === -1000);
        if (otherIndex !== -1) {
          list.splice(otherIndex, 0, {id: null, proportion: ''})
          // list.push(,);
        } else {
          list.push({id: null, proportion: ''});
        }
      }

    },

    getFilterOptionData(type) {
      let list = [];
      switch (type) {
        case "ingredients":
          list = this.filterIngredientOptions.filter(item => item.disabled === false);
          break;
        case "terpenes":
          list = this.filterTerpenesOptions.filter(item => item.disabled === false);
          break;
      }
      return list;
    },

    getFilterOptionDataById(type, id) {
      let list = [];
      switch (type) {
        case "ingredients":
          list = this.filterIngredientOptions.find(option => option.id === id);
          break;
        case "terpenes":
          list = this.filterTerpenesOptions.find(option => option.id === id);
          break;
      }
      return list;
    },
    onIngredientDelete(type, index) {
      console.log('onIngredientDelete')
      let list = this.getTypeData(type);
      if (list == null) {
        return;
      }
      list.splice(index, 1);
      this.onIngredientBlur(type, 0)
    },

    onIngredientSelectChange(type, index, id) {
      console.log(`index = ${index}, select id = ${id}`)
      let list = this.getTypeData(type);
      if (list == null) {
        return;
      }

      const filterOption = this.getFilterOptionDataById(type,id);

      list[index].id = id
      list[index].text = filterOption.text
      this.validateIngredients(type, false, true)
      this.onIngredientBlur(type, index)
      // this.ingredientSelect[index].value = e;
    },

    onIngredientValueChange(index, proportion) {
      const idle = this.calcIdleIngredient('ingredients', index);
      if (proportion >= idle) {
        proportion = idle
      }
      console.log(`onIngredientValueChange IDLE = ${idle}`)

      this.$nextTick(() => {
        // this.ingredientValue[index].proportion = `${proportion}`

        this.$set(this.ingredientValue[index], `proportion`, `${proportion}`)
      })
      // this.$set(this.ingredientValue, index, Object.assign(this.ingredientValue[index], {
      //   proportion: proportion,
      //   showInputError: false
      // }))
    },

    onTerpenesValueChange(index, proportion) {
      const idle = this.calcIdleIngredient('terpenes', index);
      console.log(`onTerpenesValueChange IDLE = ${idle}`)
      console.log(this.terpenesValue[index])
      if (parseInt(proportion) >= idle) {
        proportion = idle
      }
      this.$nextTick(() => {
        this.terpenesValue[index].proportion = `${proportion}`
      })
      // this.$set(this.terpenesValue[index], `proportion`, `${proportion}`)
      // this.onIngredientBlur('terpenes', 0)
    },
    calcIdleIngredient(type, index) {
      const list = this.getTypeData(type);
      if (list == null) {
        return 0;
      }
      const currentCalcList = list
          .filter((item, i) => item.id > 0 && index !== i);


      let otherList = [];
      //判断另一个剩余的
      if (type === "ingredients") {
        otherList = this.getTypeData("terpenes").filter((item, i) => item.id > 0);
      } else {
        otherList = this.getTypeData("ingredients").filter((item, i) => item.id > 0);
      }

      const result = otherList.concat(currentCalcList).map(item => item.proportion)
          .reduce((prev, current) => (parseFloat(prev) || 0) + (parseFloat(current) || 0), 0);

      return 100 - result;
    },
    calcAllIdleIngredient() {
      const result = [].concat(this.ingredientValue).concat(this.terpenesValue)
          .filter((item, i) => item.id > 0)
          .map(item => item.proportion)
          .reduce(
              (prev, current) =>
                  (parseFloat(prev) || 0) + (parseFloat(current) || 0),
              0
          );
      console.log(`当前填入的百分比总合 calcAllIdleIngredient ${100 - result}`);
      return 100 - result;
    },
  }
}
</script>


<style scoped lang="scss">

.ingredient-wrap {
  & > * {
    margin-top: 10px;
  }
}

.input-percent-wrap {
  & > * {
    margin-right: 4px;
  }
}

.input-disabled {
  background: #e9ecef;
}

.select-error {
  border: 1px solid red;
}

.percent-input {
  width: 46px;
  padding-left: 4px;
  padding-right: 4px;
  text-align: center;
}
</style>