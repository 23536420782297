var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Layout",
    [
      _c("PageHeader", { attrs: { title: _vm.title, items: _vm.items } }),
      _c(
        "b-card",
        [
          _vm.show
            ? _c(
                "b-form",
                { on: { submit: _vm.onSubmit, reset: _vm.onReset } },
                [
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { attrs: { cols: "8" } },
                        [
                          _c(
                            "FormRow",
                            {
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "right",
                                    fn: function() {
                                      return [
                                        _c("CountText", {
                                          attrs: {
                                            margin: "",
                                            len: _vm.form.modelName.length,
                                            max: "100"
                                          }
                                        })
                                      ]
                                    },
                                    proxy: true
                                  }
                                ],
                                null,
                                false,
                                2295451140
                              )
                            },
                            [
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    label: "Flavor Name:",
                                    "label-cols-lg": "3",
                                    "label-align-lg": "right",
                                    "label-for": "flavor-name",
                                    "label-class": "requiredRow"
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "vo-column" },
                                    [
                                      _c("b-form-input", {
                                        attrs: {
                                          required: "",
                                          oninvalid:
                                            "setCustomValidity('Please enter');",
                                          oninput:
                                            "setCustomValidity('');if(value.length > 128)value = value.slice(0, 128)",
                                          placeholder: "Please enter"
                                        },
                                        model: {
                                          value: _vm.form.modelName,
                                          callback: function($$v) {
                                            _vm.$set(_vm.form, "modelName", $$v)
                                          },
                                          expression: "form.modelName"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _c("IngredirentEditor", {
                            on: { change: _vm.onIngredientChange }
                          })
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { ref: "container" },
                        [
                          _vm.pieChartWidth !== 0
                            ? _c("FlavorPieChart", {
                                attrs: {
                                  legend: _vm.legend,
                                  series: _vm.series,
                                  height: "200px",
                                  width: _vm.pieChartWidth
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { attrs: { lg: "8" } },
                        [
                          _c(
                            "FormRow",
                            [
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    label: "Usage Effects Tags:",
                                    "label-cols-lg": "3",
                                    "label-align-lg": "right",
                                    "label-for": "usage-effects-tags",
                                    "label-class": "requiredRow"
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "vo-row align-items-center h-100"
                                    },
                                    [
                                      _c(
                                        "b-form-checkbox-group",
                                        {
                                          model: {
                                            value: _vm.form.usageEffectTags,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.form,
                                                "usageEffectTags",
                                                $$v
                                              )
                                            },
                                            expression: "form.usageEffectTags"
                                          }
                                        },
                                        _vm._l(
                                          _vm.types.productGenreList,
                                          function(tag, index) {
                                            return _c(
                                              "b-form-checkbox",
                                              {
                                                key: index,
                                                attrs: { value: tag.id }
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(tag.labelName) + " "
                                                )
                                              ]
                                            )
                                          }
                                        ),
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  void 0
                                ],
                                2
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { lg: "8" } },
                        [
                          _c(
                            "FormRow",
                            [
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    label: "Flavor Picture:",
                                    "label-cols-lg": "3",
                                    "label-align-lg": "right",
                                    "label-for": "brand-logo",
                                    "label-class": "requiredRow"
                                  }
                                },
                                [
                                  _c("input", {
                                    staticClass: "filestyle",
                                    staticStyle: {
                                      position: "absolute",
                                      clip: "rect(0px, 0px, 0px, 0px)"
                                    },
                                    attrs: {
                                      type: "file",
                                      "data-input": "false",
                                      id: "filestyle-1",
                                      tabindex: "-1",
                                      required: !_vm.form.flavorPicture,
                                      oninvalid:
                                        "setCustomValidity('Please upload');",
                                      accept: "image/*",
                                      oninput: "setCustomValidity('');"
                                    },
                                    on: {
                                      change: function($event) {
                                        return _vm.upload($event)
                                      }
                                    }
                                  }),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "bootstrap-filestyle input-group"
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass: "group-span-filestyle",
                                          attrs: { tabindex: "0" }
                                        },
                                        [
                                          _vm.form.flavorPicture
                                            ? _c("div", [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "upload-img-wrap"
                                                  },
                                                  [
                                                    _c("img", {
                                                      staticClass: "upload-img",
                                                      attrs: {
                                                        src:
                                                          _vm.form.flavorPicture
                                                      }
                                                    }),
                                                    _c("img", {
                                                      staticClass: "close-img",
                                                      attrs: {
                                                        src: require("@/assets/images/brand/ic_close.png")
                                                      },
                                                      on: {
                                                        click:
                                                          _vm.onRemoveBrandLogo
                                                      }
                                                    })
                                                  ]
                                                )
                                              ])
                                            : _vm._e(),
                                          _c(
                                            "b-button",
                                            {
                                              staticClass: "custom-width",
                                              attrs: { variant: "info" },
                                              on: {
                                                click: function($event) {
                                                  return _vm.uploadFile()
                                                }
                                              }
                                            },
                                            [_vm._v("upload picture")]
                                          ),
                                          _c("span", [
                                            _vm._v(
                                              " Recommended size of picture: 120 pixels * 120 pixels, supporting JPG and PNG formats. "
                                            )
                                          ])
                                        ],
                                        1
                                      )
                                    ]
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { attrs: { lg: "8" } },
                        [
                          _c(
                            "FormRow",
                            [
                              _c("b-col", { attrs: { offset: "3" } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "vo-row align-items-center justify-content-between"
                                  },
                                  [
                                    _c(
                                      "b-button",
                                      {
                                        staticClass: "custom-width",
                                        attrs: {
                                          type: "submit",
                                          variant: "primary",
                                          disabled: _vm.confirmDisabled
                                        }
                                      },
                                      [_vm._v("Save ")]
                                    ),
                                    _c(
                                      "b-button",
                                      {
                                        staticClass: "operate-btn",
                                        attrs: {
                                          variant: "primary",
                                          type: "reset"
                                        }
                                      },
                                      [_vm._v("Save&Add Other Flavor ")]
                                    ),
                                    _c(
                                      "b-button",
                                      {
                                        staticClass: "custom-width",
                                        attrs: { type: "reset" }
                                      },
                                      [_vm._v("Cancel")]
                                    )
                                  ],
                                  1
                                )
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c("UploadFile", {
        attrs: {
          showUploadModal: _vm.showUploadModal,
          oldFileList: _vm.editUploadList,
          path: _vm.uploadPath,
          fileType: _vm.fileType,
          maxNumber: _vm.maxNumber
        },
        on: { FinishEvent: _vm.finishEvent, CancelEvent: _vm.cancelEvent }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }